/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

@font-face {
  font-family: panton-black;
  src: url("assets/fonts/panton_black.otf");
}
@font-face {
  font-family: panton;
  src: url("assets/fonts/panton.otf");
}
@font-face {
  font-family: panton-bold;
  src: url("assets/fonts/panton_bold.otf");
}

* {
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  font-family: panton, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

select {
  background-color: transparent;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 2px;
}

h4 {
  font-family: panton-bold, sans-serif;
}

button, h4 {
  margin: 0;
}

.primary-button,
.secondary-button {
  cursor: pointer;
  font-family: panton-bold, sans-serif;
  height: 37px;
}

.primary-button:hover:enabled,
.secondary-button:hover:enabled {
  background-color: black;
  color: #22f4ae;
}

.primary-button {
  border: none;
  background-color: #22f4ae;
}

.secondary-button {
  border: 3px solid black;
  background-color: white;
}

.primary-button:disabled,
.secondary-button:disabled {
  cursor: default;
  opacity: 0.5;
  border-color: grey;
}

input.ng-touched.ng-invalid {
  border-color: red;
}
